import { Controller } from '@hotwired/stimulus'
import TomSelect from 'tom-select';

export default class extends Controller {
  static targets = ["options",  "tags"]

  static values = { minLength: { type: Number, default: 2 } }

  connect () {
    const options = Array.from(this.optionsTarget.querySelectorAll("option"))
      .map(e => { return { value: e.value, text: e.value} })

    const items = this.tagsTarget.value.split(",").map(s => s.trim()).filter(item => item)

    this.selector = new TomSelect(this.tagsTarget, {
      options: options,
      items: items,
      multiple: true,
      create: true,
      closeAfterSelect: true,
      createFilter: this.filter.bind(this),
      plugins: {
        remove_button: {}
      }
    })
  }

  disconnect () {
    this.selector.destroy()
  }


  filter (input) {
    return input.length >= this.minLengthValue;
  }

}
