import { Controller } from "@hotwired/stimulus";
import debounce from "debounce";

export default class extends Controller {

  static targets = ["submit"]

  initialize () {
    this.submit = debounce(this.submit.bind(this), 300)
  }

  submit () {
    this.submitTarget.click()
  }


  submitTargetConnected (e) {
    e.hidden = true;
  }
}
