import { Controller } from "@hotwired/stimulus";

import Uppy from '@uppy/core';

import {
  DragDrop,
  Dashboard,
  ThumbnailGenerator,
  XHRUpload,
} from 'uppy'

import '@uppy/core/src/style.scss';
import '@uppy/drag-drop/src/style.scss';

export default class extends Controller {
  static targets = "input"

  inputTargetConnected(el) {
    this.fileUpload(el)
  }

  fileUpload(fileInput) {
    const hiddenInput = document.querySelector('.upload-data'),
      imagePreview = document.querySelector('.upload-preview img'),
      titleField = document.querySelector('.upload-title'),
      formGroup = fileInput.parentNode;

    // remove our file input in favor of Uppy's
    formGroup.removeChild(fileInput)

    const uppy = new Uppy({ autoProceed: true })
      .use(XHRUpload, { endpoint: "/upload", allowMultipleUploadsBatches: false, restrictions: { maxNumberOfFiles: 1  } })
      .use(ThumbnailGenerator, { thumbnailHeight: 250 })
      .use(DragDrop, { target: formGroup, height: 250 })

    uppy.on('thumbnail:generated', (file, preview) => { 
      imagePreview.src = preview
    })

    // Once a file has successfully been uploaded, store it's identifying
    // data in the hidden upload field
    uppy.on('upload-success', (file, response) => {
      const uploadedFileData = response.body["data"],
        filename = uploadedFileData["metadata"].filename

      hiddenInput.value = JSON.stringify(uploadedFileData)
      titleField.placeholder = filename.replace(/^.*\/|\.[^.]*$/g, '').replace(/_/, ' ')
    })
  }

}
